<template>
  <div class="login-section-form-data">
    <form id="login-data-form" v-if="$locale" :key="id" v-on:submit.prevent>
      <div class="block-input">
        <label>{{ $locale["input-user"] }}</label>
        <input
          type="text"
          name="userName"
          :placeholder="$locale['input-user-placeholder']"
          required
          @blur="validateInput($event, { tooltip: false })"
          :value="savedUserName"
        />
      </div>
      <div class="block-input">
        <div class="InputLabelDouble _pass" type="password">
          <label ellipsis for="userPass">{{ $locale["input-password"] }}</label>
          <a class="ShowPassButton" @click="togglePassword">
            <Icon class="password" name="eye" />
            <Icon class="text" name="eye-slash" />
          </a>
        </div>
        <input
          type="password"
          name="userPass"
          :placeholder="$locale['input-password-placeholder']"
          required
          @blur="validateInput($event, { tooltip: false })"
          @keydown.enter="sendData('login-data-form')"
          :value="savedUserPass"
        />
      </div>
      <div class="block-options">
        <div class="login-section-options">
          <a class="link-btn">
            <label class="userRememberme">
              <input type="checkbox" name="rememberme" value="male" class="mycheck circle" :checked="isRememberme" />
              <span>{{ $locale["rememberme-label"] }}</span>
            </label>
          </a>
          <a class="link-btn underline" @click="lostPass">{{ $locale["lost-pass-btn"] }}</a>
        </div>
      </div>
      <div class="block-input">
        <input type="nosubmit" :value="$locale['login-btn']" readonly @click="sendData('login-data-form')" />
      </div>
    </form>
  </div>
</template>

<script>
import UserCommon from "./UserCommon";
export default {
  mixins: [UserCommon],
  methods: {
    validateInput: function($event, $config) {
      return this.$validate.validateInput($event, $config);
    },
    sendData: function(formID) {
      const isValidForm = this.$validate.validateForm(`#${formID}`, { tooltip: false });
      if (isValidForm) {
        this.ialert.go({ type: "loader" });
        const _data = new FormData(document.getElementById(formID));
        this.userRememberme();
        const sedData = this.$api.post(process.env.VUE_APP_API_HOST + "user/login", _data);
        sedData.then((response) => this.sendSuccess(response, `#${formID}`));
        sedData.catch((response) => this.sendError(response, `#${formID}`));
      }
    },
    sendSuccess: async function(response) {
      if (response?.data?.success) {
        await this.$store.dispatch("updateUser", response.data);
        await this.$store.dispatch("getProfile");
        this.$store.dispatch("loginSuccessRedirect");
        this.ialert.close();
      } else {
        this.ialert.go({ message: response?.data?.message, unique: true, title: this.$locale["error-auth-title"] });
      }
    },
    sendError: function(response) {
      this.ialert.go({ message: response, unique: true, title: "Error" });
    },
    lostPass: function(options) {
      const title = `${this.$locale["lost-pass-title"]}`;
      const message = `<small>${this.$locale["lost-pass-message"]}</small>`;
      const html = `<div hpadding>
                      <div class="block-input ialert-input">
                        <input type="email" name="email" placeholder="${this.$locale["input-email-placeholder"]}" value="${options?.value ?? ""}" 
                        class="${options?.emailclass ?? ""}" />
                      </div>
                    </div>`;

      this.ialert.go({ title, message, html, type: "confirm", unique: true }).then((response) => {
        const emailbox = response?.content?.querySelector('[name="email"]');
        if (response?.action !== "accept") {
          //Cacel nothing to do
        } else if (!emailbox?.value) {
          this.lostPass({ emailclass: "data-error" });
        } else if (!this.$global.validEmail(emailbox?.value)) {
          this.lostPass({ value: emailbox.value, emailclass: "data-error" });
        } else {
          this.ialert.go({ type: "loader" });
          const sedData = this.$api.post(process.env.VUE_APP_API_HOST + "user/lostpass", { email: emailbox.value });
          sedData.then((response) => this.lostPassSuccess(response));
          sedData.catch((response) => this.lostPassError(response));
        }
      });
    },
    lostPassSuccess: function(response) {
      if (response?.data?.success) {
        localStorage.setItem("lostpass", response.data.email);
        const alert = this.ialert.go({ message: response?.data?.message, unique: true, title: this.$locale["lost-pass-title"] });
        alert.then((response) => {
          this.$router.push(`/auth/code`);
        });
      } else {
        this.ialert.go({ message: response?.data?.message, unique: true, title: this.$locale["lost-pass-title"] });
      }
    },
    lostPassError: function(response) {
      this.ialert.go({ message: response, unique: true, title: "Error" });
    },
  },
};
</script>
